import request from '@/utils/request'
// 选区列表（所有页面通用） 树
export function areaList(query) {
  return request({
    url: '/wisdom/wisdom-device-area/list',
    method: 'get',
    params: query
  })
}

// 省市区 分页
export function areaPage(query) {
  return request({
    url: '/wisdom/wisdom-device-area/page',
    method: 'get',
    params: query
  })
}

// 省市区 详情
export function areaInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-area/detail',
    method: 'get',
    params: query
  })
}

// 省市区 新增
export function areaAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-area/add',
    method: 'post',
    data
  })
}

// 省市区 编辑
export function areaEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-area/edit',
    method: 'post',
    data
  })
}

// 省市区 删除
export function areaDel(data) {
  return request({
    url: '/wisdom/wisdom-device-area/delete',
    method: 'post',
    data
  })
}

// 小区 分页
export function communityPage(query) {
  return request({
    url: '/wisdom/wisdom-device-area-community/page',
    method: 'get',
    params: query
  })
}

// 小区 详情
export function communityInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-area-community/detail',
    method: 'get',
    params: query
  })
}

// 小区 新增
export function communityAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-area-community/add',
    method: 'post',
    data
  })
}

// 小区 编辑
export function communityEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-area-community/edit',
    method: 'post',
    data
  })
}

// 小区 删除
export function communityDel(data) {
  return request({
    url: '/wisdom/wisdom-device-area-community/delete',
    method: 'post',
    data
  })
}

// 楼栋 分页
export function buildingPage(query) {
  return request({
    url: '/wisdom/wisdom-device-area-building/page',
    method: 'get',
    params: query
  })
}

// 楼栋 详情
export function buildingInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-area-building/detail',
    method: 'get',
    params: query
  })
}

// 楼栋 新增
export function buildingAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-area-building/add',
    method: 'post',
    data
  })
}

// 楼栋 编辑
export function buildingEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-area-building/edit',
    method: 'post',
    data
  })
}

// 楼栋 删除
export function buildingDel(data) {
  return request({
    url: '/wisdom/wisdom-device-area-building/delete',
    method: 'post',
    data
  })
}
